<template>
  <div class="o-order-info main-info__container">
    <div class="main-info__image-wrapper">
      <SfImage
        src="/assets/thank-you-page/order-icon.svg"
        :alt="$t('order icon')"
        class="main-info__image"
      />
    </div>
    <div class="main-info__block">
      <SfHeading
        :title="$t('Order № {orderNumber} has been placed.', { orderNumber: orderNumber })"
        :level="2"
        class="main-info__title"
      />
      <div class="main-info__text">
        <p>
          {{ $t('You can already track the status') }}
          <SfLink
            :link="localizedRoute('/my-account/orders')"
            class="main-info__link sf-link--primary"
          >
            {{ $t('in the personal account') }}
          </SfLink>
        </p>
      </div>
      <div class="main-info__footer">
        <SfButton
          class="main-info__button"
          data-transaction-name="Go To Home"
          @click="$router.push(localizedRoute(formatRegionUrl('/')))"
        >
          {{ $t('Return to home') }}
        </SfButton>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SfHeading,
  SfLink,
  SfButton,
  SfImage
} from '@storefront-ui/vue'
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'OMainInfo',
  methods: { formatRegionUrl },
  components: {
    SfLink,
    SfHeading,
    SfButton,
    SfImage
  },
  props: {
    orderNumber: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/components/organisms/o-thank-you-page";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.main-info {
  &__container {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: $tablet-min) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;

    @media only screen and (min-width: $tablet-min) {
      margin: 0;
      flex: 1 0 130px;
    }

    @media only screen and (min-width: $desktop-min) {
      margin: 0 16px 0 0;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: $tablet-min) {
      align-items: flex-start;
    }
  }

  &__title {
    --heading-padding: 0;
    --heading-title-font-size: var(--font-size-20);
    --heading-title-font-weight: var(--font-medium);
    --heading-title-margin: 0 0 var(--spacer-15);
    --heading-title-color: var(--black);
  }

  &__text {
    text-align: center;
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    color: var(--black);

    @media only screen and (min-width: $tablet-min) {
      text-align: left;
    }

    p {
      margin: 0;

      @media only screen and (min-width: $desktop-min) {
        margin: 0 0 15px;
      }
    }

    a {
      display: inline-block;
    }
  }

  &__button {
    display: none;

    @media only screen and (min-width: $desktop-min) {
      display: flex;
      &:hover {
        background-color: var(--orange-hover);
      }
    }
  }
}
</style>
